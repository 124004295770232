<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">USER GROUP ACCESS</h3>
      <el-select v-model="group" placeholder="Select User Group" @change="getModules">
        <el-option v-for="group in groups" :key="group.id" :value="group.id" :label="group.name"></el-option>
      </el-select>
    </div>
    <el-table :data="data" border>
      <el-table-column label="MODULE" prop="sam_id_name"></el-table-column>
      <el-table-column label="MODULE ITEM" prop="sami_id_name"></el-table-column>
      <el-table-column label="STATUS" prop="isallow">
        <template slot-scope="props">
          <el-switch v-model="props.row.is_allow"></el-switch>
        </template>
      </el-table-column>
    </el-table>
      <div class="controls">
        <el-button type="primary" @click="confirmUpdate">Save Changes</el-button>
      </div>
  </el-card>
</template>

<script>
export default {
  data () {
    return {
      initialState: [],
      data: [],
      groups: [],
      group: null,
      form: {
        em_id: this.$store.getters.user.employeeId,
        ids_allow: [],
        ids_notallow: []
      }
    }
  },
  mounted () {
    this.getUserGroups()
  },
  methods: {
    getUserGroups () {
      this.$http
        .get('sysadmin.systemaccess/group-list')
        .then(res => {
          this.groups = res.data.body
        })
    },
    getModules () {
      this.$http
        .get(`sysadmin.systemaccess/groupitem-list/${this.group}`)
        .then(res => {
          this.data = res.data.body
          this.data = res.data.body.map(row => {
            row.is_allow = Boolean(parseInt(row.is_allow))
            return row
          })
          this.initialState = this.data
        })
    },
    handleSelectionChange (val) {
      this.form.ids_allow = val.map(item => item.id)
    },
    confirmUpdate () {
      this.$confirm('Proceed with the changes?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info'
      })
        .then(() => {
          this.update()
        })
    },
    update () {
      this.form.ids_allow = this.initialState.filter(initial => {
        if (!initial.is_allow) {
          return false
        }
        const hasChanged = this.data.find(changed => changed.id === initial.id)
        return initial.is_allow === hasChanged.is_allow
      }).map(shift => shift.id)
      this.form.ids_notallow = this.initialState.filter(initial => {
        if (initial.is_allow) {
          return false
        }
        const hasChanged = this.data.find(changed => changed.id === initial.id)
        return initial.is_allow === hasChanged.is_allow
      }).map(shift => shift.id)
      this.form.ids_notallow = this.data.filter(shift => {
        return !this.form.ids_allow.includes(shift.id) ? shift.id : false
      })
        .map(shift => shift.id)
      this.$http
        .post('sysadmin.systemaccess/groupitem-approval', this.form)
        .then(res => {
          this.$message.success('The changes are complete!')
          this.getModules()
        }).catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    getStatusType (type) {
      if (type === '1') {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>
